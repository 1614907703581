import { useState } from 'react'
import './FreshersRegistration.css'


function FreshersRegistration() {
  const scriptURL = 'https://script.google.com/macros/s/AKfycby-g9syPNK2ofrmAwUX9Hi-WkJb7F7kAD_ujqcF3A-BegZ7OE5GN6NfPH4sZGy4g5aEPg/exec';
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setButtonText('Please Wait...');
    fetch(scriptURL, {
      method: 'POST',
      body: new FormData(e.target),
    })
      .then((response) => {
        alert('Thank you! Your form is submitted successfully.');
        window.location.reload();
      })
      .catch((error) => {
        console.error('Error!', error.message);
        setIsSubmitting(false);
        setButtonText('Register');  // Revert button text if submission fails
      });
      // .catch((error) => console.error('Error!', error.message));
  };
  const [Name, setname] = useState('');
  const [rollNo, setrollno] = useState('');
  const [email, setemail] = useState('');
  const [hall, sethall] = useState('');
  const [phone, setphone] = useState('');
  const [otherInvolvements, setinvolvements] = useState('');
  const [city, setcity] = useState('');
  const [whyJoin, setwhyjoin] = useState('');
  const [buttonText, setButtonText] = useState('Register');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handlename = (event) => {
    setname(event.target.value);
  };
  const handleroll = (event) => {
    setrollno(event.target.value);
  };
  const handlemail = (event) => {
    setemail(event.target.value);
  };
  const handlehall = (event) => {
    sethall(event.target.value);
  };
  const handlephone = (event) => {
    setphone(event.target.value);
  };
  const handlewhyjoin = (event) => {
    setwhyjoin(event.target.value);
  };
  const handleinvolvements = (event) => {
    setinvolvements(event.target.value);
  };
  const handlecity = (event) => {
    setcity(event.target.value);
  };

  return (
    <>
      <div class="BODY">

        {/* <!-- Modal --> */}
        <div class="back-color">
          <div class="full-screen">

            <div class="card">
              <div class="main-form">
                <div class="left-part">
                  <div class="">
                    <form method="post" onSubmit={handleSubmit}
                      action="https://script.google.com/macros/s/AKfycby-g9syPNK2ofrmAwUX9Hi-WkJb7F7kAD_ujqcF3A-BegZ7OE5GN6NfPH4sZGy4g5aEPg/exec"
                      name="contactForm">
                      <h3 class="main-heading">WELCOME FRESHERS!</h3>
                        <div class="line">
                          <div class="details">
                            <label for="" class="our-q">Name *</label>
                            <input type="text" class="ans" name="Name" id="name" placeholder="Your name" value={Name} onChange={handlename} required />
                          </div>
                        </div>

                        {/* <!-- Roll Number --> */}

                        <div class="line">
                          <div class="details">
                            <label for="" class="our-q">Roll Number *</label>
                            <input type="text" class="ans" name="Roll No." placeholder="Your Roll.No." value={rollNo} onChange={handleroll} required />
                          </div>
                        </div>

                        {/* <!-- Email --> */}

                        <div class="line">
                          <div class="details">
                            <label for="" class="our-q">Email *</label>
                            <input type="text" class="ans" name="Email" id="email" placeholder="Your email" value={email} onChange={handlemail} required />
                          </div>
                        </div>

                        {/* <!-- Hall --> */}

                        <div class="line">
                          <div class="details">
                            <label for="" class="our-q">Hall *</label>
                            <input type="text" class="ans" name="Hall" id="phone" placeholder="Hall" value={hall} onChange={handlehall} required />
                          </div>
                        </div>

                        {/* <!-- Phone --> */}

                        <div class="line">
                          <div class="details">
                            <label for="" class="our-q">Phone *</label>
                            <input type="text" class="ans" name="Contact Number" id="phone" placeholder="Phone No." value={phone} onChange={handlephone} required />
                          </div>
                        </div>

                        {/* <!-- Portfolio --> */}

                        <div class="line">
                          <div class="details">
                            <label for="" class="our-q">Portfolio *</label>
                            <select type="text" class="ans" name="Portfolio" placeholder="Portfolio" required>
                              <option value="">Select</option>
                              <option value="Event Management">Event Management</option>
                              <option value="Web Development">Web Development</option>
                              <option value="Design">Design</option>
                            </select>
                          </div>
                        </div>

                        {/* <!-- Time Slots --> */}

                        <div class="line">
                          <div class="details">
                            <label for="" class="our-q">Preferred Date *</label>
                            <select type="text" class="ans" name="Time Slots" placeholder="Time Slots" required>
                              <option value="">Select</option>
                              {/* <option value="24th August">24th Aug, Saturday</option> */}
                              <option value="25th August">25th Aug, Sunday</option>
                              <option value="26th August">26th Aug, Monday</option>
                              {/* <option value="27th August">27th Aug, Tuesday</option> */}
                            </select>
                          </div>
                        </div>

                        {/* <!-- Other involvements --> */}

                        <div class="line">
                          <div class="details">
                            <label for="" class="our-q">Other Involvements *</label>
                            <input type="text" class="ans" name="Other Involvements" placeholder="Your other Involvements" value={otherInvolvements} onChange={handleinvolvements} required />
                          </div>
                        </div>

                        {/* <!-- City --> */}

                        <div class="line">
                          <div class="details">
                            <label for="" class="our-q">Hometown *</label>
                            <input type="text" class="ans" name="City" placeholder="City" value={city} onChange={handlecity} required />
                          </div>
                        </div>

                        {/* <!-- Why you want to join ? --> */}

                        <div class="line">
                          <div class="details">
                            <label for="" class="our-q">Why do you want to join RCA ? *</label>
                            <input type="text" class="ans" name="Why do you want to join RCA ?"
                              placeholder="Why do you want to join RCA ?" value={whyJoin} onChange={handlewhyjoin} required />
                          </div>
                        </div>
                        {/* <!-- finish--> */}


                        <div class="btn-box">
                          <input type="submit" disabled={isSubmitting} value={buttonText} id="submit" class="submit-btn" />
                        </div>

                      {/* </form> */}
                    </form>
                  </div>
                </div>
                <div class="right-part">
                  <div class="contact-inf">

                    <img src="/img/FreshersRegistration/rcaglow.png" class="rca-logo" alt="..." />

                    <br /><br /><br />
                    <h3 class="contact">Contact Information</h3>
                    <p class="rca-name">Rajasthan Cultural Association
                      IIT Kharagpur</p>
                    <ul class="ul11">
                      <li class="dot">
                        <span class=""></span>
                        <span class="">IIT Kharagpur
                          West Bengal 721302</span>
                      </li>
                      <li class="dot">
                        <span class=""></span>
                        <span class="">+91-8302434347</span>
                      </li>
                      <li class="dot">
                        <span class=""></span>
                        <span class="text">rca.iitkgp@gmail.com</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FreshersRegistration
